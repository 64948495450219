import React from "react";
import { App, Layout } from "antd";
import Component5 from "../assets/Component5.png";
import Jagjit from "../assets/Jagjit.png";
import Flahybase_Website from "../assets/flahybase_website.jpg";
import Flahybase_Website2 from "../assets/flahybase_website2.jpg";
import Flahybase_Website3 from "../assets/flahybase_website3.png";
import Flahybase_Website4 from "../assets/flahybase_website4.png";
// import Flahybase_Website5 from "../assets/flahybase_website5.png";
import Flahybase_Website6 from "../assets/flahybase_website6.mov";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";
import { Appearance } from "./Appearance";

const { Content } = Layout;

export function Flahybase() {
  return (
    <App>
      <Layout>
        <Content>
          <NavigationBar />
          <div
            className=" flex flex-col min-h-screen justify-items-center items-center py-6 pt-36 pb-44"
            style={{
              //   background:
              //     "linear-gradient(to bottom ,  #06bf79  0%, #233830 100%)",
              // background: "linear-gradient(to bottom ,  #59833B 0%, #233830 100%)",
              //   background: "linear-gradient(to bottom ,  #5f9e33 0%, #233830 100%)",
              //   background:
              //     "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
              background:
                "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
            }}
          >
            <div className=" flex flex-col">
              <p className="  px-10 py-10 flex font-acorn text-5xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl text-[#8FDBC2] text-center transition-all duration-500 ease-in-out">
                Flahybase.
              </p>
            </div>
            <div className="flex flex-col mt-20 sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row  w-10/12 sm:w-10/12 md:w-6/12 lg:w-6/12 xl:w-6/12 2xl:w-6/12 justify-between">
              <div className="flex flex-col">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Timeline
                </p>
                <p className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  2023 - Present
                </p>
              </div>
              <div className="flex flex-col mt-10 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Team
                </p>
                <div className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  <div className="group flex relative flex-row w-11">
                    <img
                      src={Component5}
                      alt=""
                      className=" object-cover rounded-full border-white border-opacity-10 border-3 sm:rounded-full md:rounded-full lg:rounded-full xl:rounded-full 2xl:rounded-full"
                    />
                    <div className="absolute bottom-14 w-auto bg-[#8FDBC2] rounded-lg py-1 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                      <p className="text-[#233830] font-gt text-nowrap text-base">
                        Sehajvir Singh
                      </p>
                    </div>
                  </div>
                  <div className="group flex relative flex-row w-11">
                    <img
                      src={Jagjit}
                      alt=""
                      className=" object-cover rounded-full border-white border-opacity-10 border-3 sm:rounded-full md:rounded-full lg:rounded-full xl:rounded-full 2xl:rounded-full"
                    />
                    <div className="absolute bottom-14 w-auto bg-[#8FDBC2] rounded-lg py-1 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                      <p className="text-[#233830] font-gt text-nowrap text-base">
                        Jagjit Singh
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-10 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Role
                </p>
                <p className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  Full Stack Developer
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mt-24 w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12">
              <img
                src={Flahybase_Website}
                alt=""
                className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
              />
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="font-acorn text-[#8FDBC2] text-5xl">Overview</p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                Flahybase is a pioneering startup specializing in Computational
                Biomedicine for Oncology. Flahybase is based in the heart of
                Silicon Valley, California. Its mission is to revolutionize the
                way we approach cancer treatment by leveraging the power of
                computational biology and machine learning. As a Full-Stack
                Developer, I spearheaded the design and creation of a responsive
                website for Flahybase.
              </p>
              <div
                className="flex flex-row self-center justify-between px-6 py-7 hover:cursor-pointer bg-[#3d6254] hover:bg-[#35594b] transition ease-in-out  w-full rounded-lg mt-14"
                onClick={() => window.open("https://flahybase.com", "_blank")}
              >
                <p className="font-gt text-[#8FDBC2] text-lg">
                  {" "}
                  Curious about Flahybase? Dive a little deeper on what it is
                </p>
                <p className="font-gt text-[#8FDBC2] text-xl">&#8599;</p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mt-24 w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12">
              <img
                src={Flahybase_Website2}
                alt=""
                className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
              />
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className=" font-gt text-[#D3EDE4] text-xl leading-9">
                As a Full-Stack Developer, I spearheaded the design and creation
                of a responsive website for a pioneering startup specializing in
                Computational Biomedicine for Oncology. Employing technologies
                such as React.js and Tailwind CSS, I ensured a seamless user
                experience across all devices. Leveraging Docker for deployment
                operations significantly enhanced efficiency – accelerating
                update rollouts by 50% and reducing rollback time by 40%.
                Furthermore, hosting the website on an Amazon Web Services (AWS)
                instance underscores our commitment to robust infrastructure,
                ensuring scalability and reliability for the application. This
                project not only showcased my technical prowess but also
                demonstrated my ability to deliver impactful solutions that meet
                the demands of modern web development.
              </p>
            </div>
            <div className="w-full flex mt-24 flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start">
              <div className="flex flex-col w-full sm:w-full md:w-1/5 lg:w-1/5 xl:w-1/5 2xl:w-1/5 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-10 2xl:mx-10 h-auto">
                <img
                  src={Flahybase_Website3}
                  alt=""
                  className=" object-cover rounded-xl"
                />
              </div>
              <div className="flex flex-col mt-14 sm:mt-14 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 sm:w-5/5 md:w-4/5 lg:w-4/5 xl:w-4/5 2xl:w-4/5 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-10 2xl:mx-10 h-auto">
                <img
                  src={Flahybase_Website4}
                  alt=""
                  className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
                />
              </div>
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="font-gt text-[#D3EDE4] text-xl leading-9">
                In today's digital age, ensuring a seamless web experience
                across multiple devices is not just an advantage—it's a
                necessity. My commitment to responsive design stems from a deep
                understanding of user-centric development and a desire to create
                accessible, engaging digital environments for all users,
                regardless of the device they use.
              </p>

              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                Why Responsive Design Matters:{" "}
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;User Engagement: A responsive website provides a
                consistent experience that can increase user satisfaction and
                engagement.{" "}
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Reach and Accessibility: With more users accessing
                the web from mobile devices, a responsive design ensures that
                everyone can access the site with the same ease and
                functionality.{" "}
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;SEO Benefits: Search engines favor responsive
                websites, as they provide a better user experience and are
                easier to crawl compared to separate mobile versions.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                My Approach:{" "}
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Fluid Grids: I employ fluid grid layouts that use
                percentages rather than fixed pixels to ensure content resizes
                beautifully to fit the screen it's being viewed on.{" "}
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Flexible Images: All images on the website are
                configured to be flexible, allowing them to scale without
                distorting their resolution, ensuring clarity and quality across
                devices.{" "}
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Media Queries: I leverage CSS media queries to
                apply different styles for different media types and conditions,
                enhancing the site's responsiveness without compromising on
                functionality.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center mt-24 w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 ">
              <video
                src={Flahybase_Website6}
                controls
                autoplay
                loop
                muted
                className="object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl w-full"
              >
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="font-acorn text-[#8FDBC2] text-5xl">
                Technologies Used
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                For the development of Flahybase Incorporation's website, an
                advanced tech stack was employed to ensure optimal performance
                and user experience. The front-end was built using React.js and
                Tailwind CSS. React.js allowed for a dynamic and responsive user
                interface, making it an excellent choice for handling the
                stateful, real-time interactions that are crucial for a
                biomedical computational platform. Tailwind CSS provided
                utility-first building blocks that enabled custom styling
                without leaving the HTML, thus speeding up the development
                process and ensuring a consistent design.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                For build operations, Docker was utilized to containerize the
                application, which simplified both development and deployment by
                creating consistent environments that are easily replicable.
                This use of Docker ensured that the application could be
                deployed swiftly and reliably, reducing the complexities
                typically associated with deployment.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                Hosting was managed on an AWS server, providing scalable and
                secure cloud hosting with high availability and reduced latency.
                AWS's robust ecosystem offered integrated features such as
                automated scaling and load balancing, which are essential for
                handling the high computational loads and data throughput
                required by a computational biomedicine platform.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                This tech stack not only facilitated a streamlined build and
                deployment process but also ensured that the application was
                maintainable and scalable. This setup is ideal for the demands
                of biomedical computation, emphasizing security, reliability,
                and user-centric design.
              </p>
            </div>
            <div className="flex flex-col mt-4 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                *Images are the property of Flahybase Incorporation. I do not
                claim ownership of these images and did not produce or design
                them. They are used here solely to showcase my work experience
                at Flahybase.
              </p>
            </div>
            <div
              onClick={() => (window.location.href = "/peerreviewapplication")}
              className="group flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 justify-center items-center border-3 border-transparent hover:border-[#2b5f4e] duration-1000 transition-all ease-in-out py-10 sm:py-10 md:py-24 lg:py-24 xl:py-24 2xl:py-24 rounded-full cursor-pointer"
            >
              <p className="font-acorn text-center text-[#8FDBC2] text-4xl sm:text-4xl md:text-8xl lg:text-8xl xl:text-8xl 2xl:text-8xl group-transition-transform duration-300 ease-in-out transform group-hover:-translate-y-2.5">
                Peer Review Application.
              </p>
            </div>
          </div>
        </Content>
        <Footer />
        <Appearance />
      </Layout>
    </App>
  );
}
