// import React from "react";

// export function Appearance() {
//   return (
//     <div className="bg-[#233830] w-full flex flex-col h-24 justify-center items-center pb-20">
//       <div className="-400 w-10/12 flex flex-col h-16 justify-end">
//         <div className="w-full  justify-between items-end flex flex-row h-12">
//           {[...Array(24).keys()].map((index) => (
//             <div
//               key={index}
//               className="group bg-[#233830] relative flex justify-center cursor-pointer items-end w-10 h-6 hover:h-10 transition-all duration-500"
//             >
// <div
//   className={`${
//     (index >= 0 && index <= 5) || (index >= 18 && index <= 23)
//       ? "bg-[#568674] h-3"
//       : "bg-[#D3EDE4] h-5"
//   } w-[2px] group-hover:h-10 rounded-md group-hover:transition-all duration-500`}
// ></div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useState } from "react";

export function Appearance() {
  // State and event handler
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  return (
    <div className="bg-[#233830] w-full flex flex-col h-24 justify-center items-center pb-20">
      <div className=" w-10/12 flex flex-col h-16 justify-end">
        <div className="w-full  justify-between items-end flex flex-row h-12">
          {[...Array(24).keys()].map((index) => (
            <div
              key={index}
              className="group bg-[#233830] relative flex justify-center cursor-pointer items-end w-10 h-6 hover:h-10 transition-all duration-500"
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => setHoveredIndex(null)} // Reset on mouse leave
            >
              {/* Track hovered index using state */}
              <div
                className={`${
                  (index >= 0 && index <= 5) || (index >= 18 && index <= 23)
                    ? "bg-[#568674] h-3"
                    : "bg-[#D3EDE4] h-5"
                } w-[2px] ${
                  hoveredIndex === index ? "h-10" : ""
                } group-hover:h-10 rounded-md group-hover:transition-all duration-500`}
              ></div>
              {/* <div
                className={`${
                  index === hoveredIndex
                    ? "bg-[#568674] h-10"
                    : index >= hoveredIndex - 1 && index <= hoveredIndex + 1
                    ? "bg-[#D3EDE4] h-8"
                    : "bg-[#D3EDE4] h-5"
                } w-[2px] rounded-md transition-all duration-500`}
              ></div> */}
              {/* <div
                className={`${
                  index === hoveredIndex
                    ? "bg-[#568674] h-10"
                    : index >= hoveredIndex - 1 && index <= hoveredIndex + 1
                    ? "bg-[#D3EDE4] h-8"
                    : "bg-[#D3EDE4] h-5"
                } w-[2px] rounded-md transition-all duration-500`}
              ></div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
