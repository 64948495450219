import React, { useEffect, useState } from "react";

export function NavigationBar() {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      const newOpacity = position > 0 ? 0 : 0.7;
      setOpacity(newOpacity);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className=" z-20 fixed flex flex-row text-[#D3EDE4] justify-center font-gt text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg w-full mt-10">
      <div
        className="flex py-4 px-6  rounded-full backdrop-blur-lg border-none"
        // style={{
        //   backgroundColor: `hsla(158, 23%, 18%, 0)`,
        //   // backgroundColor: `hsla(128, 33%, 88%, 0)`,
        // }}
      >
        <a className="hover:text-[#D3EDE4]" href="/about">
          <p
            className="py-1.5 px-4 rounded-3xl "
            style={{
              backgroundColor:
                window.location.pathname === "/about" ||
                window.location.pathname === "/"
                  ? "rgb(143 220 194 / 10%)"
                  : "",
            }}
          >
            About
          </p>
        </a>
        <a className="hover:text-[#D3EDE4]" href="/work">
          <p
            className="py-1.5 px-4 rounded-3xl "
            style={{
              backgroundColor:
                window.location.pathname === "/work"
                  ? "rgb(143 220 194 / 10%)"
                  : "",
            }}
          >
            Work
          </p>
        </a>
        <a className="hover:text-[#D3EDE4]" href="/contact">
          <p
            className="py-1.5 px-4 rounded-3xl "
            style={{
              backgroundColor:
                window.location.pathname === "/contact"
                  ? "rgb(143 220 194 / 10%)"
                  : "",
            }}
          >
            Contact
          </p>
        </a>
      </div>
    </div>
  );
}
