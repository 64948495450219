import React from "react";
import { App, Layout } from "antd";
import Component5 from "../assets/Component5.png";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";
import { Appearance } from "./Appearance";

const { Content } = Layout;

export function GetMeARoomie() {
  return (
    <App>
      <Layout>
        <Content>
          <NavigationBar />
          <div
            className=" flex flex-col min-h-screen justify-items-center items-center py-6 pt-36 pb-44"
            style={{
              // background:
              //   "linear-gradient(to bottom ,  #06bf79  0%, #233830 100%)",
              // background: "linear-gradient(to bottom ,  #59833B 0%, #233830 100%)",
              //   background: "linear-gradient(to bottom ,  #5f9e33 0%, #233830 100%)",
              //   background:
              //     "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
              background:
                "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
            }}
          >
            <div className=" flex flex-col">
              <p className="  px-10 py-10 flex font-acorn text-5xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl text-[#8FDBC2] text-center transition-all duration-500 ease-in-out">
                Get Me A Roomie.
              </p>
            </div>
            <div className="flex flex-col mt-20 sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row  w-10/12 sm:w-10/12 md:w-6/12 lg:w-6/12 xl:w-6/12 2xl:w-6/12 justify-between">
              <div className="flex flex-col">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Timeline
                </p>
                <p className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  01.2023 - Present
                </p>
              </div>
              <div className="flex flex-col mt-10 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Team
                </p>
                <div className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  <div className="group flex relative flex-row w-11 ">
                    <img
                      src={Component5}
                      alt=""
                      className=" object-cover rounded-full border-white border-opacity-10 border-3 sm:rounded-full md:rounded-full lg:rounded-full xl:rounded-full 2xl:rounded-full"
                    />
                    <div className="absolute bottom-14 w-auto bg-[#8FDBC2] rounded-lg py-1 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                      <p className="text-[#233830] font-gt text-nowrap text-base">
                        Sehajvir Singh
                      </p>
                    </div>
                  </div>
                  {/* <div className="group flex relative flex-row w-auto mt-2 ml-1">
                    <p className="flex flex-row  font-gt text-[#8FDBC2] text-base">
                      {" "}
                      +11 other amazing people.
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="flex flex-col mt-10 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Role
                </p>
                <p className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  Full-Stack Web Developer
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col mt-24 justify-center items-center">
              <p className="font-acorn text-[#8FDBC2] text-5xl">
                Coming real soon...
              </p>
            </div>

            {/* <div
              onClick={() => (window.location.href = "/work")}
              className="group flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 justify-center items-center border-3 border-transparent hover:border-[#2b5f4e] duration-1000 transition-all ease-in-out py-10 sm:py-10 md:py-24 lg:py-24 xl:py-24 2xl:py-24 rounded-full cursor-pointer"
            >
              <p className="font-acorn text-center text-[#8FDBC2] text-4xl sm:text-4xl md:text-8xl lg:text-8xl xl:text-8xl 2xl:text-8xl group-transition-transform duration-300 ease-in-out transform group-hover:-translate-y-2.5">
                Get Me a Roomie.
              </p>
            </div> */}
          </div>
        </Content>
        <Footer />
        <Appearance />
      </Layout>
    </App>
  );
}
