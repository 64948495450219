import React, { useState, useEffect } from "react";
import axios from "axios";
import star1 from "../star-1.svg";
import star2 from "../star-2.svg";
import comp4 from "../assets/Component4.png";
import comp101 from "../assets/Component101.png";
import P1 from "../assets/P1.JPG";
import P2 from "../assets/P2.JPG";
import P3 from "../assets/P3.jpg";
import P4 from "../assets/P4.JPG";
import P5 from "../assets/P5.JPG";
import P6 from "../assets/P6.jpg";
import P7 from "../assets/P7.JPG";
import P8 from "../assets/P8.JPG";
import L1 from "../assets/L1.JPG";
import L2 from "../assets/L2.JPG";
import L3 from "../assets/L3.JPG";
import L4 from "../assets/L4.JPG";
import L5 from "../assets/L5.JPG";
import L6 from "../assets/L6.JPG";
import L7 from "../assets/L7.JPG";
import L8 from "../assets/L8.JPG";
import { Layout } from "antd";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";
import { Appearance } from "./Appearance";

const { Content } = Layout;

export function About() {
  const [randomImage1, setRandomImage1] = useState("");
  const [randomImage2, setRandomImage2] = useState("");
  const [temperature, setTemperature] = useState("°C");

  useEffect(() => {
    const API_KEY = "d6b351beb9c5562fde59de101ac6de51";
    const URL = `https://api.openweathermap.org/data/2.5/weather?q=Vancouver,ca&units=metric&appid=${API_KEY}`;

    axios
      .get(URL)
      .then((response) => {
        const temp = Math.round(response.data.main.temp);
        setTemperature(`${temp}°C`);
      })
      .catch((error) => {
        console.error("Error fetching weather data:", error);
        setTemperature("Failed to load temperature");
      });
  }, []);

  useEffect(() => {
    const imagesL = [
      { src: L1, location: "Cloverdale, BC" },
      { src: L2, location: "Belcarra, BC" },
      { src: L3, location: "Kelowna, BC" },
      { src: L4, location: "Kelowna, BC" },
      { src: L5, location: "Squamish, BC" },
      { src: L6, location: "Squamish, BC" },
      { src: L7, location: "Vancouver, BC" },
      { src: L8, location: "Kelowna, BC" },
    ];

    const imagesP = [
      { src: P1, location: "Kelowna, BC" },
      { src: P2, location: "Kelowna, BC" },
      { src: P3, location: "Big White Kelowna, BC" },
      { src: P4, location: "Big White Kelowna, BC" },
      { src: P5, location: "Belcarra, BC" },
      { src: P6, location: "Kelowna, BC" },
      { src: P7, location: "Vancouver, BC" },
      { src: P8, location: "Ludhiana, Punjab" },
    ];

    // Get a random index within the array length
    const randomIndex1 = Math.floor(Math.random() * imagesL.length);
    const randomIndex2 = Math.floor(Math.random() * imagesP.length);

    // Set the random image filenames
    setRandomImage1(imagesL[randomIndex1]);
    setRandomImage2(imagesP[randomIndex2]);
  }, []);

  return (
    <>
      <Layout className="flex flex-col min-h-screen ">
        <Content className="flex flex-col min-h-screen justify-between ">
          <NavigationBar />

          <div
            className=" flex flex-col min-h-screen justify-items-center items-center py-6 pt-28 bg-gradient-to-b from-[#36735B] from-1%  to-[#233830] to-80%"
            // style={{
            //   // background: "linear-gradient(to bottom ,  #2b7257  0%, #233830 100%)",
            //   // background: "linear-gradient(to bottom ,  #59833B 0%, #233830 100%)",
            //   // background: "linear-gradient(to bottom ,  #5f9e33 0%, #233830 100%)",
            //   background:
            //     "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
            // }}
          >
            <div className=" flex flex-col">
              <div className="flex  flex-row justify-end items-end">
                <img
                  className="flex flex-col relative justify-end  w-10 sm:w-14 md:w-14 lg:w-14 xl:w-16 2xl:w-18 "
                  src={star1}
                  alt=""
                />
              </div>

              <p className="  px-10 py-4 flex font-acorn text-5xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl text-[#8FDBC2] text-center">
                I'm Séhajvir.{" "}
              </p>
              <div className="flex  flex-row justify-start items-start">
                <img
                  className="flex flex-col relative justify-end w-10 sm:w-14 md:w-14 lg:w-14 xl:w-16 2xl:w-18 "
                  src={star2}
                  alt=""
                />
              </div>
            </div>
            <div className="w-full flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row ">
              <div className=" flex flex-col justify-center items-center mt-16 sm:w-5/5 md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5  mx-10 h-auto">
                <img className="w-2/3" src={comp4} alt="" />
                <div className="flex flex-row w-2/3 px-6 py-4 items-center bg-[#8FDBC2] drop-shadow-xl">
                  <svg
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="#598979"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20.5013 0.0664062C9.48964 0.0664062 0.5625 8.99331 0.5625 20.005C0.5625 31.0171 9.48964 39.9433 20.5013 39.9433C31.5142 39.9433 40.4404 31.0171 40.4404 20.005C40.4404 8.99403 31.5142 0.0673586 20.5011 0.0673586L20.5013 0.0664062V0.0664062ZM29.6451 28.8236C29.56 28.963 29.4483 29.0842 29.3163 29.1804C29.1843 29.2766 29.0346 29.3458 28.8758 29.3841C28.717 29.4223 28.5522 29.4289 28.3909 29.4034C28.2296 29.3779 28.0748 29.3208 27.9356 29.2355C23.2542 26.3759 17.3608 25.7283 10.4204 27.314C10.099 27.3871 9.76174 27.3296 9.48275 27.1541C9.20375 26.9787 9.00584 26.6996 8.9325 26.3783C8.89597 26.2192 8.89119 26.0545 8.91842 25.8935C8.94565 25.7326 9.00436 25.5786 9.09118 25.4404C9.17801 25.3021 9.29126 25.1824 9.42443 25.088C9.5576 24.9936 9.70808 24.9264 9.86726 24.8902C17.4625 23.1543 23.9775 23.9021 29.2332 27.114C29.8189 27.4736 30.0046 28.2378 29.6451 28.8236V28.8236ZM32.0856 23.3938C31.6356 24.1259 30.6785 24.3569 29.9475 23.9069C24.588 20.6119 16.4182 19.6578 10.0789 21.5821C9.25679 21.8305 8.38845 21.3671 8.13893 20.5464C8.01986 20.1518 8.06213 19.7262 8.25647 19.3627C8.4508 18.9993 8.78134 18.7277 9.1756 18.6076C16.4168 16.4105 25.4189 17.4747 31.5737 21.2569C32.3046 21.7069 32.5356 22.664 32.0856 23.394V23.3938ZM32.2951 17.7405C25.8689 13.9236 15.2665 13.5726 9.13107 15.4347C8.14583 15.7335 7.10393 15.1774 6.80536 14.1921C6.66179 13.7188 6.71209 13.2078 6.94521 12.7715C7.17832 12.3353 7.57515 12.0094 8.04845 11.8657C15.0915 9.7276 26.7999 10.1407 34.1985 14.5328C34.4093 14.6576 34.5935 14.8227 34.7404 15.0187C34.8874 15.2147 34.9942 15.4378 35.0548 15.6752C35.1154 15.9126 35.1286 16.1596 35.0936 16.4021C35.0586 16.6446 34.9761 16.8778 34.8508 17.0883C34.327 17.9745 33.1794 18.2666 32.2961 17.7405H32.2951Z"></path>
                  </svg>
                  <div className="flex flex-col font-gt text-sm ml-6 text-[#39594D]">
                    <p>On Repeat</p>
                    <a
                      className="text-[#233830] hover:text-[#233830]"
                      href="https://open.spotify.com/track/3eP13S8D5m2cweMEg3ZDed"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className=" font-bold">Virginia Beach • Drake</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className=" flex flex-col mt-16 pr-16 sm:w-5/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 mx-10 h-auto">
                <p className="font-acorn text-[#8FDBC2] text-6xl ">
                  I'm a Full-Stack Developer working from {temperature}{" "}
                  Vancouver, Canada.
                </p>
                <p className="font-gt text-[#D3EDE4] text-xl mt-8">
                  For over 4 years, I've delved into the dynamic realms of
                  frontend development, contributing my expertise to diverse
                  projects spanning web and mobile platforms.
                </p>
                <p className="font-gt text-[#D3EDE4] text-xl mt-8">
                  With my most recent stint as a full stack developer, I've
                  cultivated a comprehensive understanding of both frontend and
                  backend intricacies. Presently, my passion lies in the
                  captivating domain of web development, where I am dedicated to
                  crafting immersive digital experience.
                </p>
                {/* <p className="font-acorn text-[#8FDBC2] text-6xl ">
                  I'm a Full Time Napper working from {temperature} UBC, Canada.
                </p>
                <p className="font-gt text-[#D3EDE4] text-xl mt-8">
                  For over 4 years, I've delved into the dynamic realms of
                  sleeping really hard and contributing my expertise to diverse
                  methods of napping.
                </p>
                <p className="font-gt text-[#D3EDE4] text-xl mt-8">
                  With my most recent incident as a full time napper, I've
                  cultivated a comprehensive understanding of both sleeping and
                  waking up. Presently, my passion lies in the captivating
                  domain of napping, where I am dedicated to crafting immersive
                  sleeping experience. I also miss people's phone calls and keep
                  them waiting for hours under my building.
                </p> */}
              </div>
            </div>
            <div className="w-full mt-20 flex justify-center items-center">
              <div className="grid grid-cols-12 gap-4 flex-col w-11/12  py-10">
                <div className="bg-[#8FDBC2] rounded-full py-4 col-start-1 col-span-12 sm:col-start-1 sm:col-span-12 md:col-start-9 md:col-span-4 lg:col-start-9 lg:col-span-4 xl:col-start-9 xl:col-span-4 2xl:col-start-9 2xl:col-span-4 flex flex-row items-center justify-between px-8">
                  <div className="flex flex-col">
                    <p className=" font-gt text-lg font-bold text-[#233830]">
                      Get Me A Roomie
                    </p>{" "}
                    <p className=" font-gt text-base text-[#233830]">
                      Full-Stack Web Developer
                    </p>
                  </div>
                  <div>
                    <p className=" font-acorn text-xl text-[#233830]">
                      10.23 ~
                    </p>
                  </div>
                </div>
                <div className="bg-[#8FDBC2] rounded-full py-4 col-start-1 col-span-12 sm:col-start-1 sm:col-span-12 md:col-start-7 md:col-span-4 lg:col-start-7 lg:col-span-4 xl:col-start-7 xl:col-span-4 2xl:col-start-7 2xl:col-span-4 flex flex-row items-center justify-between px-8">
                  <div className="flex flex-col">
                    <p className=" font-gt text-lg font-bold text-[#233830]">
                      FlahyBase Inc.
                    </p>{" "}
                    <p className=" font-gt text-base text-[#233830]">
                      Full-Stack Web Developer
                    </p>
                  </div>
                  <div>
                    <p className=" font-acorn text-xl text-[#233830]">
                      09.23 - 11.23
                    </p>
                  </div>
                </div>
                <div className="bg-[#8FDBC2] rounded-full py-4 col-start-1 col-span-12 sm:col-start-1 sm:col-span-12 md:col-start-4 md:col-span-4 lg:col-start-4 lg:col-span-4 xl:col-start-4 xl:col-span-4 2xl:col-start-4 2xl:col-span-4 flex flex-row items-center justify-between px-8">
                  <div className="flex flex-col">
                    <p className=" font-gt text-lg font-bold text-[#233830]">
                      Learnification Tech.
                    </p>
                    <p className=" font-gt text-base text-[#233830]">
                      Capstone Project
                    </p>
                  </div>
                  <div>
                    <p className=" font-acorn text-xl text-[#233830]">
                      04.23 - 09.23
                    </p>
                  </div>
                </div>
                <div className="bg-[#8FDBC2] rounded-full py-4 col-start-1 col-span-12 sm:col-start-1 sm:col-span-12 md:col-start-2 md:col-span-4 lg:col-start-2 lg:col-span-4 xl:col-start-2 xl:col-span-4 2xl:col-start-2 2xl:col-span-4 flex flex-row items-center justify-between px-8">
                  <div className="flex flex-col">
                    <p className=" font-gt text-lg font-bold text-[#233830]">
                      IRLY
                    </p>
                    <p className=" font-gt text-base text-[#233830]">
                      Frontend Developer
                    </p>
                  </div>
                  <div>
                    <p className=" font-acorn text-xl text-[#233830]">
                      03.22 - 05.23
                    </p>
                  </div>
                </div>
                <div className="bg-[#8FDBC2] rounded-full py-4 col-start-1 col-span-12 sm:col-start-1 sm:col-span-12 md:col-start-1 md:col-span-6 lg:col-start-1 lg:col-span-6 xl:col-start-1 xl:col-span-6 2xl:col-start-1 2xl:col-span-6 flex flex-row items-center justify-between px-8">
                  <div className="flex flex-col">
                    <p className=" font-gt text-lg font-bold text-[#233830]">
                      UBC, Okanagan
                    </p>
                    <p className=" font-gt text-base text-[#233830]">
                      BSc. Computer Science
                    </p>
                  </div>
                  <div>
                    <p className=" font-acorn text-xl text-[#233830]">
                      09.21 - 08.23
                    </p>
                  </div>
                </div>
                {/* <div className="bg-[#8FDBC2] rounded-full py-4 col-start-1 col-span-12 sm:col-start-1 sm:col-span-12 md:col-start-2 md:col-span-5 lg:col-start-2 lg:col-span-5 xl:col-start-2 xl:col-span-5 2xl:col-start-2 2xl:col-span-5 flex flex-row items-center justify-between px-6">
                  <div className="flex flex-col">
                    <p className=" font-gt text-lg font-bold text-[#233830]">
                      Hexertech Tech. Inc.
                    </p>
                    <p className=" font-gt text-base text-[#233830]">
                      Qualitative Developer
                    </p>
                  </div>
                  <div>
                    <p className=" font-acorn text-xl text-[#233830]">
                      05.21 - 08.21
                    </p>
                  </div>
                </div> */}
                {/* <div className="bg-[#8FDBC2] rounded-full py-4 col-start-1 col-span-12 sm:col-start-1 sm:col-span-12 md:col-start-1 md:col-span-4 lg:col-start-1 lg:col-span-4 xl:col-start-1 xl:col-span-4 2xl:col-start-1 2xl:col-span-4 flex flex-row items-center justify-between px-6">
                  <div className="flex flex-col">
                    <p className=" font-gt text-lg font-bold text-[#233830]">
                      SFU, Burnaby
                    </p>
                    <p className=" font-gt text-base text-[#233830]">
                      BSc. Computer Science
                    </p>
                  </div>
                  <div>
                    <p className=" font-acorn text-xl text-[#233830]">
                      09.18 - 04.21
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="w-full mt-10 flex justify-center items-center">
              <div className="w-10/12  flex flex-col">
                <p className="font-acorn text-[#8FDBC2] text-6xl">
                  Let's collaborate if you're committed to sustainability,
                  education, equality, or carbon neutrality.
                </p>
                <p className="font-gt text-[#D3EDE4] text-xl mt-8 md:pr-48 lg:pr-48 xl:pr-48 2xl:pr-48">
                  I believe we should leave this Earth as good as or better than
                  we found it for future generations; my goal is to contribute
                  to those ideals in whatever way I can. If you feel the same,
                  I'd love to talk.
                </p>
              </div>
            </div>
            <div className="w-full mt-16 flex flex-col justify-center items-center">
              <div className="w-full md:w-11/12 lg:w-11/12 xl:w-11/12 2xl:w-11/12 bg-[#305045] pt-24 px-10 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row rounded-none md:rounded-t-[64px] lg:rounded-t-[64px] xl:rounded-t-[64px] 2xl:rounded-t-[64px]">
                <div className="w-full md:w-2/4 lg:w-2/4 xl:w-2/4 2xl:w-2/4 h-auto px-14 flex flex-col">
                  <p className="font-acorn font-bold text-4xl text-[#598E7D]">
                    01
                  </p>
                  <p className="font-acorn font-bold text-4xl pt-6 text-[#8FDBC2]">
                    Comprehensive Solution Crafting
                  </p>
                  <p className="font-gt text-xl pt-4 text-[#D3EDE4]">
                    As a full stack developer, I orchestrate end-to-end
                    solutions, seamlessly integrating frontend and backend
                    technologies. From conceptualization to deployment, I handle
                    every aspect of the development process, ensuring cohesive
                    and efficient solutions tailored to meet client needs.
                  </p>
                </div>
                <div className="w-full pt-10 md:pt-0 lg:pt-0 xl:pt-0 2xl:pt-0 md:w-2/4 lg:w-2/4 xl:w-2/4 2xl:w-2/4 h-auto px-14 flex flex-col">
                  <p className="font-acorn font-bold text-4xl text-[#598E7D]">
                    02
                  </p>
                  <p className="font-acorn font-bold text-4xl pt-6 text-[#8FDBC2]">
                    Iterative Development Methodology
                  </p>
                  <p className="font-gt text-xl pt-4 text-[#D3EDE4]">
                    Employing agile methodologies, I embrace an iterative
                    approach to development, continually refining and enhancing
                    features based on user feedback and evolving requirements.
                    By breaking down complex tasks into manageable iterations, I
                    foster adaptability and responsiveness, delivering
                    high-quality products on schedule.
                  </p>
                </div>
              </div>

              <div className="w-full md:w-11/12 lg:w-11/12 xl:w-11/12 2xl:w-11/12 bg-[#305045] py-24 px-10 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row rounded-none md:rounded-b-[64px] lg:rounded-b-[64px] xl:rounded-b-[64px] 2xl:rounded-b-[64px]">
                <div className="w-full -mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 md:w-2/4 lg:w-2/4 xl:w-2/4 2xl:w-2/4 h-auto px-14 flex flex-col">
                  <p className="font-acorn font-bold text-4xl text-[#598E7D]">
                    03
                  </p>
                  <p className="font-acorn font-bold text-4xl pt-6 text-[#8FDBC2]">
                    Cross-Functional Collaboration
                  </p>
                  <p className="font-gt text-xl pt-4 text-[#D3EDE4]">
                    Collaboration lies at the heart of my workflow as a full
                    stack developer. I actively engage with cross-functional
                    teams, including designers, product managers, and QA
                    engineers, to align on project objectives and streamline
                    development processes. By fostering open communication and
                    knowledge sharing, I cultivate a synergistic environment
                    conducive to innovation and growth.
                  </p>
                </div>
                <div className="w-full pt-10 md:pt-0 lg:pt-0 xl:pt-0 2xl:pt-0 md:w-2/4 lg:w-2/4 xl:w-2/4 2xl:w-2/4 h-auto px-14 flex flex-col">
                  <p className="font-acorn font-bold text-4xl text-[#598E7D]">
                    04
                  </p>
                  <p className="font-acorn font-bold text-4xl pt-6 text-[#8FDBC2]">
                    Continuous Learning and Skill Enhancement
                  </p>
                  <p className="font-gt text-xl pt-4 text-[#D3EDE4]">
                    Committed to staying abreast of emerging technologies and
                    industry best practices, I prioritize continuous learning
                    and skill enhancement. Whether mastering new programming
                    languages, exploring cutting-edge frameworks, or refining
                    existing skills, I invest in my professional development to
                    deliver innovative solutions and remain competitive in a
                    rapidly evolving tech landscape.
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-full justify-center items-center mt-16">
                <div className="w-full sm:w-full md:w-6/12 lg:w-6/12 xl:w-6/12 2xl:w-6/12 px-10 sm:px-10 md:px-0 lg:px-0 xl:px-0 2xl:px-0">
                  <p className="font-gt text-xl text-[#D3EDE4]">
                    After completing my BSc in Computer Science at UBC, I
                    embarked on a journey into the world of web development—a
                    path that quickly ignited my passion for design and
                    programming. It was during my second year that I discovered
                    my affinity for crafting visually appealing and intuitive
                    user interfaces, prompting me to delve deeper into frontend
                    development.
                  </p>
                  <p className="font-gt text-xl text-[#D3EDE4] mt-10">
                    As I honed my skills in HTML, CSS, and JavaScript, I found
                    myself drawn to the intricate logic and problem-solving
                    challenges inherent in backend development. Eager to expand
                    my expertise, I embraced the full stack approach, seamlessly
                    integrating frontend and backend technologies to create
                    holistic and dynamic web solutions.
                  </p>
                  <p className="font-gt text-xl text-[#D3EDE4] mt-10">
                    Beyond my professional pursuits, I am driven by a relentless
                    curiosity to explore new technologies and stay abreast of
                    industry trends. My side projects serve as a creative
                    outlet, allowing me to experiment with innovative ideas and
                    push the boundaries of web development.
                  </p>
                  <p className="font-gt text-xl text-[#D3EDE4] mt-10">
                    Outside of coding, I find solace in the simple pleasures of
                    life—a freshly brewed cup of coffee by the beach, the
                    melodic strains of music filling the air, and the joy of
                    embarking on adventures with loved ones and of course these
                    photos below showcase my passion for photography.
                  </p>
                </div>
              </div>
              <div className="w-full mt-20 mb-36  flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
                <div className="group w-full rounded-none sm:rounded-none md:rounded-2xl lg:rounded-2xl xl:rounded-2xl 2xl:rounded-2xl justify-center items-center sm:w-full md:w-4/6 lg:w-4/6 xl:w-4/6 2xl:w-4/6  ml-0 sm:ml-0 md:ml-32 lg:ml-32 xl:ml-32 2xl:ml-32 mr-0 sm:mr-0 md:mr-2 lg:mr-2 xl:mr-2 2xl:mr-2 flex relative">
                  <img
                    src={randomImage1.src}
                    alt=""
                    className=" object-cover rounded-none sm:rounded-none md:rounded-2xl lg:rounded-2xl xl:rounded-2xl 2xl:rounded-2xl h-[500px] w-full"
                  />
                  <div className="absolute bottom-4 left-4 bg-[#233830] rounded-lg py-2 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                    <p className="text-[#D3EDE4] font-gt text-base">
                      {randomImage1.location}
                    </p>
                  </div>
                </div>
                <div className="group w-full rounded-none sm:rounded-none md:rounded-2xl lg:rounded-2xl xl:rounded-2xl 2xl:rounded-2xl justify-center items-center sm:w-full md:w-2/6 lg:w-2/6 xl:w-2/6 2xl:w-2/6  mr-0 sm:mr-0 md:mr-32 lg:mr-32 xl:mr-32 2xl:mr-32 ml-0 sm:ml-0 md:ml-2 lg:ml-2 xl:ml-2 2xl:ml-2 flex relative">
                  <img
                    src={randomImage2.src}
                    alt=""
                    className=" object-cover mt-10 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 rounded-none sm:rounded-none md:rounded-2xl lg:rounded-2xl xl:rounded-2xl 2xl:rounded-2xl h-[500px] w-full"
                  />
                  <div className="absolute bottom-4 left-4 bg-[#233830] rounded-lg py-2 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                    <p className="text-[#D3EDE4] font-gt text-base">
                      {randomImage2.location}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <Appearance />
        </Content>
      </Layout>
    </>
  );
}
