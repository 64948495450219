import React from "react";
import star1 from "../star-1.svg";
import star2 from "../star-2.svg";
import flahybase from "../assets/flahybase.jpg";
import IRLY2 from "../assets/IRLY2.jpg";
import peerreview from "../assets/peerreview.jpg";
import { Layout } from "antd";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";
import { Appearance } from "./Appearance";
const { Content } = Layout;

export function Work() {
  // const fadeIn = useSpring({
  //   opacity: 1,
  //   from: { opacity: 0 },
  //   config: { duration: 1000 },
  // });

  // const slideIn = useSpring({
  //   marginTop: 0,
  //   from: { marginTop: 100 },
  //   config: { duration: 300 },
  // });
  return (
    <>
      <Layout className="flex flex-col min-h-screen">
        <Content className="flex flex-col min-h-full justify-between bg-red-400">
          <NavigationBar />

          <div
            className=" flex flex-col min-h-screen justify-items-center items-center py-6 pt-36 pb-44"
            style={{
              // background:
              //   "linear-gradient(to bottom ,  #06bf79  0%, #233830 100%)",
              // background: "linear-gradient(to bottom ,  #59833B 0%, #233830 100%)",
              // background: "linear-gradient(to bottom ,  #5f9e33 0%, #233830 100%)",
              background:
                "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
            }}
          >
            <div className=" flex flex-col">
              <div className="flex  flex-row justify-end items-end">
                <img
                  className="flex flex-col relative justify-end w-10 sm:w-14 md:w-14 lg:w-14 xl:w-16 2xl:w-18 transition-all duration-500 ease-in-out"
                  src={star2}
                  alt=""
                />
              </div>

              <p className="  px-10 py-4 flex font-acorn text-5xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl text-[#8FDBC2] text-center transition-all duration-500 ease-in-out">
                Hi. I'm Séhajvir.
              </p>
            </div>
            <div className=" flex flex-col ">
              <p className=" px-20 font-acorn text-5xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl text-[#8FDBC2] text-center transition-all duration-500 ease-in-out">
                A Developer.
              </p>
              <div className="flex  flex-row justify-start items-start">
                <img
                  className="flex flex-col relative justify-end w-10 sm:w-14 md:w-14 lg:w-14 xl:w-16 2xl:w-18 transition-all duration-500 ease-in-out"
                  src={star1}
                  alt=""
                />
              </div>
            </div>
            <p className="font-gt text-center pt-12 px-10 md:px-20 lg:px-24 xl:px-32 2xl:px-40  text-lg md:text-lg lg:text-xl xl:text-xl 2xl:text-xl text-[#D3EDE4] transition-all duration-500 ease-in-out">
              I'm passionate about creating immersive and accessible digital
              experiences that prioritize user engagement and satisfaction.
            </p>

            <p className="font-acorn text-center pt-12 px-10 md:px-20 lg:px-24 xl:px-32 2xl:px-40 text-4xl md:text-4xl lg:text-5xl xl:text-5xl 2xl:text-6xl text-[#8FDBC2] transition-all duration-500 ease-in-out">
              Projects/Work Experience
            </p>
            <div className="w-full mt-24 flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start">
              <div
                className="flex flex-col mt-16 hover:scale-y-105 hover:cursor-pointer duration-700 sm:w-5/5 md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 bg-[#D094E5] hover:bg-[#ce86e9] mx-10 rounded-[64px] h-auto overflow-hidden hover:shadow-neutral-900 hover:shadow-2xl"
                // onClick={() => window.open("https://flahybase.com", "_blank")}
                onClick={() => (window.location.href = "/flahybase")}
              >
                <p className="text-[#364442] font-extralight flex flex-col mt-10 mr-14 mx-10 uppercase tracking-widest text-right font-gt text-sm sm:text-sm md:text-lg lg:text-lg xl:text-lg 2xl:text-lg">
                  Flahybase Incorporation
                </p>
                <p className="text-[#364442] font-extralight flex flex-col mr-14 mx-10 tracking-widest text-right font-acorn text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl">
                  Full-Stack Web Developer
                </p>

                <div className="flex flex-row w-full justify-end items-end mt-12 ">
                  <img
                    className="flex flex-row rounded-br-[64px] rounded-tl-xl h-auto relative justify-end w-11/12 -mb-1 -mr-1"
                    src={flahybase}
                    alt=""
                  />
                </div>
              </div>
              <div
                className="flex flex-col mt-16 hover:scale-y-105 hover:cursor-pointer duration-700 sm:w-5/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 bg-[#A0DCD4] hover:bg-[#91dbd1] mx-10 rounded-[64px] h-auto overflow-hidden hover:shadow-neutral-900 hover:shadow-2xl"
                onClick={() =>
                  (window.location.href = "/peerreviewapplication")
                }
              >
                <p className="text-[#364442] font-extralight flex flex-col mt-10 mr-14 mx-10 uppercase tracking-widest text-right font-gt text-sm sm:text-sm md:text-lg lg:text-lg xl:text-lg 2xl:text-lg">
                  Peer Review Application
                </p>
                <p className="text-[#364442] font-extralight flex flex-col mr-14 mx-10 tracking-widest text-right font-acorn text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl">
                  Full-Stack Developer
                </p>
                <div className="flex flex-row w-full justify-end items-end mt-10">
                  <img
                    className="flex flex-row rounded-br-[64px] rounded-tl-xl relative justify-end w-10/12 -mb-1 -mr-1 h-auto"
                    src={peerreview}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start">
              <div
                onClick={() => (window.location.href = "/irly")}
                className="flex flex-col mt-32 sm:w-5/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 hover:cursor-pointer bg-[#8d86a1] hover:bg-[#7e729e] mx-10 rounded-[64px] hover:scale-y-105 duration-700 h-auto overflow-hidden hover:shadow-neutral-900 hover:shadow-2xl"
              >
                <p className="text-white flex flex-col mt-10 mr-14 mx-10 uppercase tracking-widest text-right font-gt text-sm sm:text-sm md:text-lg lg:text-lg xl:text-lg 2xl:text-lg">
                  IRLY
                </p>
                <p className="text-white font-extralight flex flex-col mr-14 mx-10 uppercase tracking-widest text-right font-acorn text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl">
                  Frontend Mobile Developer
                </p>
                <div className="flex flex-row w-full justify-end items-end mt-10">
                  <img
                    className="flex flex-row rounded-br-[64px] rounded-tl-xl relative justify-end w-11/12 -mb-1 -mr-1 h-auto"
                    src={IRLY2}
                    alt=""
                  />
                </div>
                {/* <p className="font-gt text-[#364442] text-justify text-xl mx-10 mt-4 mb-8">
                  In my role as a Quantitative Developer at Hexertech Associates
                  LLP, I've been spearheading exciting projects aimed at
                  revolutionizing our clients' equity portfolios. One of my
                  recent accomplishments involved crafting a robust Live
                  Database hosted on AWS EC2 instances, ensuring seamless access
                  to real-time portfolio data. Additionally, I've had the
                  privilege of applying our proprietary risk measures to conduct
                  in-depth portfolio risk analysis, enabling our clients to make
                  informed decisions. It's been an exhilarating journey,
                  leveraging my skills to drive impactful solutions that
                  optimize portfolio performance and mitigate risk effectively.
                </p> */}
              </div>
              <div
                className="flex flex-col mt-32 sm:w-5/5 md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 bg-[#BDDFF9] hover:bg-[#a8d4f5] mx-10 rounded-[64px] hover:scale-y-105 duration-700 h-auto overflow-hidden hover:cursor-pointer hover:shadow-neutral-900 hover:shadow-2xl"
                onClick={() => (window.location.href = "/getmearoomie")}
              >
                <p className="text-[#364442] flex flex-col mt-10 mr-14 mx-10 self-center uppercase tracking-widest text-right font-gt text-xl">
                  Get Me A Roomie
                </p>
                {/* <p className="text-[#364442] font-extralight text-center flex flex-col mr-14 mx-10 uppercase tracking-widest font-acorn text-3xl">
                  Full-Stack Developer
                </p> */}
                <p className="font-gt text-[#364442] text-justify self-center text-xl mx-10 mt-4 mb-8">
                  Coming Soon...
                </p>
              </div>
            </div>
          </div>
          <Footer />
          <Appearance />
        </Content>
      </Layout>
    </>
  );
}
