import React, { useState } from "react";
import star1 from "../star-1.svg";
import star2 from "../star-2.svg";
import { Layout } from "antd";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";
import { Appearance } from "./Appearance";
import emailjs from "emailjs-com";
import {
  Input,
  Textarea,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
const { Content } = Layout;

export function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const [missingFields, setMissingFields] = useState([]);

  const [backdrop, setBackdrop] = React.useState("blur");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkForMissingFields = () => {
    const fields = [];
    if (!formData.name) fields.push("Name");
    if (!formData.email) fields.push("Email");
    if (!formData.subject) fields.push("Subject");
    if (!formData.message) fields.push("Message");
    console.log(fields);
    return fields;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const missing = checkForMissingFields();
    if (missing.length > 0) {
      setMissingFields(missing);
      setVisible(true);
      setBackdrop(backdrop);
    } else {
      setVisible2(true);
      console.log(formData);
      const emailData = {
        from_name: formData.name,
        to_name: "Sehajvir", // Replace 'Your Name' with your actual name if needed
        reply_to: formData.email,
        message: `Subject: ${formData.subject}\n\nMessage: ${formData.message}`,
      };
      emailjs
        .send(
          "service_up7nehm",
          "template_wketc9h",
          emailData,
          "T8r1-E4t6iwTSW34q"
        )
        .then(
          (result) => {
            console.log("Email successfully sent!", result.text);
            // You can clear the form here or show a success message
          },
          (error) => {
            console.log("Failed to send email:", error.text);
          }
        );
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  };

  const handleClose = () => {
    setVisible(false);
  };
  const handleClose2 = () => {
    setVisible2(false);
  };
  return (
    <>
      <Layout className="flex flex-col min-h-screen">
        <Content className="flex flex-col min-h-full justify-between bg-red-400">
          <NavigationBar />

          <div
            className=" flex flex-col min-h-screen justify-items-center items-center py-6 pt-36 pb-44"
            style={{
              // background:
              //   "linear-gradient(to bottom ,  #06bf79  0%, #233830 100%)",
              // background: "linear-gradient(to bottom ,  #59833B 0%, #233830 100%)",
              // background: "linear-gradient(to bottom ,  #5f9e33 0%, #233830 100%)",
              background:
                "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
            }}
          >
            <div className=" flex flex-col">
              <div className="flex  flex-row justify-end items-end">
                <img
                  className="flex flex-col relative justify-end  w-10 sm:w-14 md:w-14 lg:w-14 xl:w-16 2xl:w-18 "
                  src={star1}
                  alt=""
                />
              </div>

              <p className="  px-10 py-4 flex font-acorn text-5xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl text-[#8FDBC2] text-center">
                Contact Me.{" "}
              </p>
              <div className="flex  flex-row justify-start items-start">
                <img
                  className="flex flex-col relative justify-end w-10 sm:w-14 md:w-14 lg:w-14 xl:w-16 2xl:w-18 "
                  src={star2}
                  alt=""
                />
              </div>
            </div>
            <div className=" flex flex-col mt-16 sm:w-5/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 mx-10 h-auto">
              <p className="font-acorn text-[#8FDBC2] text-6xl text-center">
                Lets Connect!
              </p>
              <p className="font-gt text-[#D3EDE4] text-xl mt-8 text-center">
                So, you want to get in touch with me? I am excited to hear from
                you. You can reach me at{" "}
                <a
                  href="mailto:pannusehajvir@gmail.com"
                  className="hover:text-[#8FDBC2]"
                >
                  {" "}
                  pannusehajvir@gmail.com{" "}
                </a>
                or you can fill out the form below and I will get back to you.
              </p>
            </div>
            <div className=" flex flex-col mt-16 w-4/5 sm:w-5/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 mx-10 h-auto justify-center items-center">
              <Input
                classNames={{
                  label: "font-gt text-slate-900 text-lg",
                  input: "font-gt text-black text-lg",
                  innerWrapper: "bg-transparent",
                  inputWrapper: [
                    "shadow-xl",
                    "bg-[#D3EDE4] bg-opacity-80",
                    "backdrop-blur-xl",
                    "hover:bg-[#D3EDE4]",
                  ],
                }}
                isRequired
                clearable
                fullWidth
                color="default"
                size="lg"
                label="Name"
                value={formData.name}
                onChange={(e) =>
                  handleChange({
                    target: { name: "name", value: e.target.value },
                  })
                }
              />
              <Input
                className="mt-14"
                classNames={{
                  label: "font-gt text-slate-900 text-lg",
                  input: "font-gt text-black text-lg",
                  innerWrapper: "bg-transparent",
                  inputWrapper: [
                    "shadow-xl",
                    "bg-[#D3EDE4] bg-opacity-80",
                    "backdrop-blur-xl",
                    "hover:bg-[#D3EDE4]",
                  ],
                }}
                isRequired
                clearable
                fullWidth
                color="default"
                size="lg"
                label="Email"
                type="email"
                value={formData.email}
                onChange={(e) =>
                  handleChange({
                    target: { name: "email", value: e.target.value },
                  })
                }
              />
              <Input
                className="mt-14"
                classNames={{
                  label: "font-gt text-slate-900 text-lg",
                  input: "font-gt text-black text-lg",
                  innerWrapper: "bg-transparent",
                  inputWrapper: [
                    "shadow-xl",
                    "bg-[#D3EDE4] bg-opacity-80",
                    "backdrop-blur-xl",
                    "hover:bg-[#D3EDE4]",
                  ],
                }}
                isRequired
                clearable
                bordered
                fullWidth
                color="default"
                size="lg"
                label="Subject"
                value={formData.subject}
                onChange={(e) =>
                  handleChange({
                    target: { name: "subject", value: e.target.value },
                  })
                }
              />
              <Textarea
                className="mt-14"
                classNames={{
                  label: "font-gt text-slate-900 text-lg",
                  input: "font-gt text-black text-lg",
                  innerWrapper: "bg-transparent",
                  inputWrapper: [
                    "shadow-xl",
                    "bg-[#D3EDE4] bg-opacity-80",
                    "backdrop-blur-xl",
                    "hover:bg-[#D3EDE4]",
                  ],
                }}
                isRequired
                bordered
                fullWidth
                color="default"
                size="lg"
                label="Message"
                value={formData.message}
                onChange={(e) =>
                  handleChange({
                    target: { name: "message", value: e.target.value },
                  })
                }
              />
              <Button
                radius="sm"
                className="flex flex-row justify-center text-white items-center mt-10 w-1/5 bg-gradient-to-b from-[#FF7F50] to-[#f4612b]"
                onClick={handleSubmit}
              >
                <p className="font-gt text-lg">Submit</p>
              </Button>
            </div>
            <Modal
              isOpen={visible}
              backdrop={backdrop}
              closeButton={<></>}
              className="bg-white"
            >
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader className="flex flex-col gap-1 self-center font-gt text-xl">
                      Oops! Something is Missing
                    </ModalHeader>
                    <ModalBody>
                      <p className="font-gt text-lg">
                        Don't forget to fill these before submitting:
                      </p>
                      <p>
                        {missingFields.map((field, index) => (
                          <span key={index}>
                            {field} <br />
                          </span>
                        ))}
                      </p>
                    </ModalBody>
                    <ModalFooter className="flex flex-row justify-center items-center">
                      <Button
                        color="danger"
                        variant="light"
                        onPress={handleClose}
                        className="font-gt text-base"
                      >
                        Close
                      </Button>
                      {/* <Button color="primary" onPress={handleClose}>
                        Action
                      </Button> */}
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
            <Modal
              isOpen={visible2}
              backdrop={backdrop}
              closeButton={<></>}
              className="bg-white"
            >
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader className="flex flex-col gap-1 self-center font-gt text-xl">
                      Thanks for the Message!
                    </ModalHeader>
                    <ModalBody>
                      <p className="font-gt text-lg">
                        I will get back to you as soon as possible.
                      </p>
                    </ModalBody>
                    <ModalFooter className="flex flex-row justify-center items-center">
                      <Button
                        color="danger"
                        variant="light"
                        onPress={handleClose2}
                        className="font-gt text-base"
                      >
                        Close
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
            {/* <Modal
              closeButton
              aria-labelledby="modal-title"
              open={visible}
              onClose={handleClose}
            >
              <Modal.Header>
                <p id="modal-title" className="text-2xl font-gt ">
                  Missing Information
                </p>
              </Modal.Header>
              <Modal.Body>
                <p>Please complete the following fields:</p>
                <ul>
                  {missingFields.map((field, index) => (
                    <li key={index}>
                      <p>{field}</p>
                    </li>
                  ))}
                </ul>
              </Modal.Body>
              <Modal.Footer>
                <Button auto flat color="error" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal> */}
          </div>
          <Footer />
          <Appearance />
        </Content>
      </Layout>
    </>
  );
}
