import React from "react";
import { App, Layout } from "antd";
import Component5 from "../assets/Component5.png";
import Prabhmeet from "../assets/prabhmeet.png";
import Lance from "../assets/Lance.png";
import Parsa from "../assets/Parsa.png";
import PeerReview from "../assets/peerreview1.jpg";
import PeerReview2 from "../assets/peerreview2.jpg";
import PeerReview3 from "../assets/peerreview3.jpg";
import PeerReview4 from "../assets/peerreview4.jpg";
import PeerReview5 from "../assets/peerreview5.jpg";
import PeerReview6 from "../assets/peerreview6.jpg";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";
import { Appearance } from "./Appearance";

const { Content } = Layout;

export function PeerReviewApplication() {
  return (
    <App>
      <Layout>
        <Content>
          <NavigationBar />
          <div
            className=" flex flex-col min-h-screen justify-items-center items-center py-6 pt-36 pb-44"
            style={{
              //   background:
              //     "linear-gradient(to bottom ,  #06bf79  0%, #233830 100%)",
              // background: "linear-gradient(to bottom ,  #59833B 0%, #233830 100%)",
              //   background: "linear-gradient(to bottom ,  #5f9e33 0%, #233830 100%)",
              //   background:
              //     "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
              background:
                "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
            }}
          >
            <div className=" flex flex-col">
              <p className="  px-10 py-10 flex font-acorn text-5xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl text-[#8FDBC2] text-center transition-all duration-500 ease-in-out">
                Peer Review Application.
              </p>
            </div>
            <div className="flex flex-col mt-20 sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row  w-10/12 sm:w-10/12 md:w-6/12 lg:w-6/12 xl:w-6/12 2xl:w-6/12 justify-between">
              <div className="flex flex-col">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Timeline
                </p>
                <p className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  04.2023 - 09.2023
                </p>
              </div>
              <div className="flex flex-col mt-10 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Team
                </p>
                <div className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  <div className="group flex relative flex-row w-11">
                    <img
                      src={Parsa}
                      alt=""
                      className=" object-cover rounded-full border-white border-opacity-10 border-3 sm:rounded-full md:rounded-full lg:rounded-full xl:rounded-full 2xl:rounded-full"
                    />
                    <div className="absolute bottom-14 w-auto bg-[#8FDBC2] rounded-lg py-1 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                      <p className="text-[#233830] font-gt text-nowrap text-base">
                        Parsa Rajabi
                      </p>
                    </div>
                  </div>
                  <div className="group flex relative flex-row w-11">
                    <img
                      src={Component5}
                      alt=""
                      className=" object-cover rounded-full border-white border-opacity-10 border-3 sm:rounded-full md:rounded-full lg:rounded-full xl:rounded-full 2xl:rounded-full"
                    />
                    <div className="absolute bottom-14 w-auto bg-[#8FDBC2] rounded-lg py-1 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                      <p className="text-[#233830] font-gt text-nowrap text-base">
                        Sehajvir Singh
                      </p>
                    </div>
                  </div>
                  <div className="group flex relative flex-row w-11">
                    <img
                      src={Prabhmeet}
                      alt=""
                      className=" object-cover rounded-full border-white border-opacity-10 border-3 sm:rounded-full md:rounded-full lg:rounded-full xl:rounded-full 2xl:rounded-full"
                    />
                    <div className="absolute bottom-14 w-auto bg-[#8FDBC2] rounded-lg py-1 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                      <p className="text-[#233830] font-gt text-nowrap text-base">
                        Prabhmeet Deol
                      </p>
                    </div>
                  </div>
                  <div className="group flex relative flex-row w-11">
                    <img
                      src={Lance}
                      alt=""
                      className=" object-cover rounded-full border-white border-opacity-10 border-3 sm:rounded-full md:rounded-full lg:rounded-full xl:rounded-full 2xl:rounded-full"
                    />
                    <div className="absolute bottom-14 w-auto bg-[#8FDBC2] rounded-lg py-1 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                      <p className="text-[#233830] font-gt text-nowrap text-base">
                        Lance Xu
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-10 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Role
                </p>
                <p className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  Full Stack Developer
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mt-24 w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 ">
              <img
                src={PeerReview}
                alt=""
                className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
              />
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="font-acorn text-[#8FDBC2] text-5xl">Overview</p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                Peer Review Application is a web application project that I
                developed for Parsa Rajabi an Instructor and Graduate Researcher
                at Simon Fraser University, as a part of my final year Capstone
                Project at UBC along with my team of 5 members. The application
                is designed to help professors and students to review and grade
                each other's work.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                The Peer Review Application is designed with a dual purpose:
                revolutionizing collaborative learning through
                instructor-designed group evaluation forms and redefining
                assignment assessments. By facilitating student evaluations of
                group members' contributions and enabling instructors to assign
                and assess peer evaluations for assignments, the application
                fosters teamwork, accountability, and peer learning. This
                dynamic approach enhances the educational experience by
                promoting active engagement, comprehensive feedback, and mutual
                growth among students.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center mt-24 w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12">
              <img
                src={PeerReview2}
                alt=""
                className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
              />
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                The objective of this project is to develop a user-friendly
                platform that facilitates the entire assignment and evaluation
                process for students and instructors. The goal is to create a
                seamless system where students can easily submit their
                assignments and receive timely feedback. Additionally, students
                will have the opportunity to evaluate a set number of
                assignments assigned by the instructor, providing valuable input
                on their peers' work. The platform will also enable students to
                evaluate the contributions of their fellow group members for
                group assignments. Moreover, students themselves will be
                evaluated by their peers in a fair and anonymous manner. This
                peer evaluation process aims to promote collaboration,
                accountability, and the development of valuable skills.
              </p>
            </div>
            <div className="w-full flex mt-24 flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start">
              <div className="flex flex-col w-full sm:w-full md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-10 2xl:mx-10  h-auto">
                <img
                  src={PeerReview3}
                  alt=""
                  className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
                />
              </div>
              <div className="flex flex-col mt-14 sm:mt-14 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 sm:w-5/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-10 2xl:mx-10 h-auto">
                <img
                  src={PeerReview4}
                  alt=""
                  className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
                />
              </div>
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                For instructors, the platform will provide a convenient
                interface to create assignments, manage evaluations, and view
                feedback. Instructors will have a comprehensive overview of
                students' progress, enabling them to assess individual and group
                performance effectively. Overall, the project's objective is to
                create an inclusive and interactive learning environment where
                students can submit assignments, receive feedback on their
                assignments, evaluate peers’ contributions towards group
                projects/assignments, and receive feedback for their own
                contributions, while instructors have the necessary tools to
                manage assignments, evaluations, and track student progress. The
                platform will empower students to engage actively in the
                learning process and foster a collaborative and enriching
                educational experience.
              </p>
            </div>
            <div className="w-full flex mt-24 flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start">
              <div className="flex flex-col w-full sm:w-full md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-10 2xl:mx-10 h-auto">
                <img
                  src={PeerReview5}
                  alt=""
                  className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
                />
              </div>
              <div className="flex flex-col mt-14 sm:mt-14 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 sm:w-5/5 md:w-9/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-10 2xl:mx-10 h-auto">
                <img
                  src={PeerReview6}
                  alt=""
                  className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
                />
              </div>
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="font-acorn text-[#8FDBC2] text-5xl">
                Technologies Used
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                In this web application project, I used popular tools and
                technologies to build a complete, well-functioning application.
                The user interface, where users interact with the app, was
                created using ReactJs, a tool that helps build responsive and
                interactive web pages. I added Tailwind CSS to make the app look
                clean and modern.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                The back-end architecture was designed using Node.js and
                Express.js, adhering to the Model-View-Controller (MVC) design
                pattern, which facilitated a structured and organized codebase.
                This approach not only streamlined the development process but
                also boosted maintainability.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                For data management, MySQL served as the robust database driver,
                ensuring efficient data handling and storage. The entire
                development workflow was managed on GitHub, utilizing issues for
                tracking and GitHub Projects as a Kanban board to maintain a
                seamless and transparent project progression. We used Drone CI
                to handle the automated parts of integrating new code changes,
                and GitHub Actions to automatically prepare our app for
                deployment.
              </p>
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="font-acorn text-[#8FDBC2] text-3xl">
                Key Technical Highlights:
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                &#8227; &nbsp;Front-end Development: Utilized ReactJs for
                building a dynamic and responsive user interface, styled with
                Tailwind CSS for a modern look and feel.
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Back-end Structure: Implemented an MVC
                architecture with Node.js and Express.js, providing a clean and
                efficient backend structure.
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Database Management: Integrated MySQL for reliable
                and scalable data storage solutions.
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Continuous Integration/Continuous Deployment
                (CI/CD): Configured Drone CI for continuous integration and
                GitHub Actions for continuous deployment, ensuring that each
                build was automated and streamlined from development to
                production.
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Development Environment: Employed Docker Desktop
                to create a consistent development environment across all local
                systems, which significantly reduced setup time and eliminated
                "it works on my machine" issues.
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;API Testing: Postman was used for comprehensive
                manual testing of backend APIs, ensuring robustness and
                reliability.
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Production Deployment: Setup the production
                environment on a Digital Ocean droplet via SSH, which was
                provided by the client, ensuring a smooth transition and stable
                deployment.
              </p>
            </div>
            <div className="flex flex-col mt-4 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                This project not only enhanced my technical skills across
                various dimensions, including full-stack development and DevOps
                practices, but also deepened my understanding of efficient
                project management and teamwork in a tech-driven environment.
              </p>
            </div>
            <div className="flex flex-col mt-4 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                *Images are the property of Learnification Technologies. I do
                not claim ownership of these images and did not produce or
                design them. They are used here solely to showcase my work
                experience at Learnification Technologies.
              </p>
            </div>
            <div
              onClick={() => (window.location.href = "/irly")}
              className="group flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 justify-center items-center border-3 border-transparent hover:border-[#2b5f4e] duration-1000 transition-all ease-in-out py-10 sm:py-10 md:py-24 lg:py-24 xl:py-24 2xl:py-24 rounded-full cursor-pointer"
            >
              <p className="font-acorn text-center text-[#8FDBC2] text-4xl sm:text-4xl md:text-8xl lg:text-8xl xl:text-8xl 2xl:text-8xl group-transition-transform duration-300 ease-in-out transform group-hover:-translate-y-2.5">
                IRLY.
              </p>
            </div>
          </div>
        </Content>
        <Footer />
        <Appearance />
      </Layout>
    </App>
  );
}
