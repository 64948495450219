import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Work } from "./Components/Work";
import { Contact } from "./Components/Contact";
import { Flahybase } from "./Components/Flahybase";
import { PeerReviewApplication } from "./Components/PeerReviewApplication";
import { About } from "./Components/About";
import { IRLY } from "./Components/IRLY";
import { GetMeARoomie } from "./Components/GetMeARoomie";
import "./App.css";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/work" element={<Work />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/flahybase" element={<Flahybase />}></Route>
          <Route
            path="/peerreviewapplication"
            element={<PeerReviewApplication />}
          ></Route>
          <Route path="/irly" element={<IRLY />}></Route>
          <Route path="/getmearoomie" element={<GetMeARoomie />}></Route>
          <Route path="/" element={<About />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
