import React from "react";
import { App, Layout } from "antd";
import Component5 from "../assets/Component5.png";
import IRLY1 from "../assets/IRLY3.jpg";
import IRLY2 from "../assets/IRLY4.jpg";
import IRLY3 from "../assets/IRLY5.jpg";
import IRLY4 from "../assets/IRLY6.jpg";
import IRLY5 from "../assets/IRLY7.jpg";
import IRLY6 from "../assets/IRLY8.jpg";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";
import { Appearance } from "./Appearance";

const { Content } = Layout;

export function IRLY() {
  return (
    <App>
      <Layout>
        <Content>
          <NavigationBar />
          <div
            className=" flex flex-col min-h-screen justify-items-center items-center py-6 pt-36 pb-44"
            style={{
              // background:
              //   "linear-gradient(to bottom ,  #06bf79  0%, #233830 100%)",
              // background: "linear-gradient(to bottom ,  #59833B 0%, #233830 100%)",
              //   background: "linear-gradient(to bottom ,  #5f9e33 0%, #233830 100%)",
              //   background:
              //     "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
              background:
                "linear-gradient(to bottom ,  #36735B 0%, #233830 100%)",
            }}
          >
            <div className=" flex flex-col">
              <p className="  px-10 py-10 flex font-acorn text-5xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl text-[#8FDBC2] text-center transition-all duration-500 ease-in-out">
                IRLY.
              </p>
            </div>
            <div className="flex flex-col mt-20 sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row  w-10/12 sm:w-10/12 md:w-6/12 lg:w-6/12 xl:w-6/12 2xl:w-6/12 justify-between">
              <div className="flex flex-col">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Timeline
                </p>
                <p className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  03.2022 - 04.2023
                </p>
              </div>
              <div className="flex flex-col mt-10 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Team
                </p>
                <div className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  <div className="group flex relative flex-row w-11 ">
                    <img
                      src={Component5}
                      alt=""
                      className=" object-cover rounded-full border-white border-opacity-10 border-3 sm:rounded-full md:rounded-full lg:rounded-full xl:rounded-full 2xl:rounded-full"
                    />
                    <div className="absolute bottom-14 w-auto bg-[#8FDBC2] rounded-lg py-1 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                      <p className="text-[#233830] font-gt text-nowrap text-base">
                        Sehajvir Singh
                      </p>
                    </div>
                  </div>
                  <div className="group flex relative flex-row w-auto mt-2 ml-1">
                    <p className="flex flex-row  font-gt text-[#8FDBC2] text-base">
                      {" "}
                      +11 other amazing people.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-10 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
                <p className="flex flex-row justify-start items-start font-gt text-[#D3EDE4] text-base">
                  Role
                </p>
                <p className="flex flex-row justify-start items-start font-gt text-[#8FDBC2] text-base mt-2">
                  Mobile Developer
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mt-24 w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12">
              <img
                src={IRLY1}
                alt=""
                className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
              />
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="font-acorn text-[#8FDBC2] text-5xl">Overview</p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                As an initial developer at IRLY, an innovative online dating
                platform, my role centered around frontend development. IRLY
                aims to shift the dating app paradigm by emphasizing personality
                over appearance, fostering deeper connections.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                Joining the IRLY team as one of the original developers, I found
                myself deeply involved in crafting an online dating app designed
                to foster meaningful connections. At IRLY, our vision was clear
                from the start: to challenge the superficial swipe culture by
                prioritizing personality and genuine interactions. My role as a
                React Native frontend developer was pivotal. I brought the
                team's creative visions to life, ensuring that every element on
                screen was not only visually appealing but also perfectly
                aligned with our user-centric design philosophy. This project
                wasn't just about coding; it was about creating a space where
                real relationships could bloom.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center mt-24 w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12">
              <img
                src={IRLY2}
                alt=""
                className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
              />
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                For project management, we relied on Jira to keep our tasks
                well-organized and to track our progress effectively.
                Communication was key in our dynamic team environment, and we
                used Slack to maintain clear and continuous dialogue among
                developers, designers, and project managers. This toolkit was
                instrumental in our ability to deliver a high-quality product
                tailored to enhance user engagement in a meaningful way.
              </p>
            </div>
            <div className="w-full flex mt-24 flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start">
              <div className="flex flex-col  sm:w-5/5 md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-10 2xl:mx-10 h-auto">
                <img
                  src={IRLY4}
                  alt=""
                  className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
                />
              </div>
              <div className="flex flex-col mt-14 sm:mt-14 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 sm:w-5/5 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-10 2xl:mx-10 h-auto">
                <img
                  src={IRLY3}
                  alt=""
                  className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
                />
              </div>
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                Every morning, the development team gathered for a brief session
                to discuss their progress, outline the tasks for the day, and
                address any challenges they were facing. This practice allowed
                each team member to provide updates directly to the tech lead,
                ensuring that any issues were promptly identified and addressed.
                The tech lead, in turn, offered guidance and redistributed
                resources as needed to maintain momentum and adhere to the
                project timeline. This agile approach significantly improved our
                efficiency and fostered a collaborative environment.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center mt-24 w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12">
              <img
                src={IRLY5}
                alt=""
                className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
              />
            </div>
            {/* <div className="w-9/12 flex mt-24 flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start">
              <div className="flex flex-col  sm:w-5/5 md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12 mx-10 h-auto">
                <img
                  src={PeerReview5}
                  alt=""
                  className=" object-cover rounded-xl"
                />
              </div>
              <div className="flex flex-col mt-14 sm:mt-14 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 sm:w-5/5 md:w-9/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 mx-10 h-auto">
                <img
                  src={PeerReview6}
                  alt=""
                  className=" object-cover rounded-xl"
                />
              </div>
            </div> */}
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="font-acorn text-[#8FDBC2] text-5xl">
                Technologies Used
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                In the development of our application, we utilized React Native
                for the frontend to leverage its robust capabilities for
                building mobile apps with a native look and feel. React Native's
                cross-platform nature allowed us to develop a consistent user
                experience across both Android and iOS devices while maintaining
                a single codebase. This choice significantly streamlined our
                development process, enabled faster iteration, and reduced the
                complexity associated with managing two separate development
                tracks for different platforms.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                For the backend, we relied on CSS for styling and layout
                designs, ensuring that our application was not only functional
                but also visually appealing. CSS played a crucial role in
                defining the look and feel of the app, allowing us to create a
                user-friendly interface that was both intuitive and engaging.
                This approach helped us maintain a seamless aesthetic continuity
                between the mobile application and any web-based components,
                reinforcing our brand's visual identity across different user
                touchpoints.
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                The deployment and operational testing of our app were carried
                out using Xcode and TestFlight. Xcode was integral for running
                builds and ensuring the app's performance was optimized for iOS
                devices. Additionally, it provided essential tools for debugging
                and streamlining the development process. TestFlight was used
                for beta testing, allowing us to gather valuable feedback from
                internal users before the public release. This step was critical
                in identifying and resolving potential issues, thereby enhancing
                the app's stability and user satisfaction upon launch.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center mt-24 w-full sm:w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12">
              <img
                src={IRLY6}
                alt=""
                className=" object-cover rounded-none sm:rounded-none md:rounded-xl lg:rounded-xl xl:rounded-xl 2xl:rounded-xl"
              />
            </div>
            <div className="flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="font-acorn text-[#8FDBC2] text-3xl">
                Key Technical Highlights:
              </p>
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                &#8227; &nbsp;Mobile Development: Leveraged React Native to
                craft a versatile and engaging mobile application, ensuring a
                seamless user experience across iOS and Android platforms.
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Cross-Platform Styling: Employed CSS for styling
                components, maintaining consistency and aesthetic appeal
                throughout the application's interface.
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Platform-Specific Build: Utilized Xcode for iOS
                and Android Studio for Android to optimize performance and
                functionality tailored to each platform's requirements.
                <span>
                  <br></br>
                </span>
                &#8227; &nbsp;Agile Workflow: Managed project tasks efficiently
                through Jira, enabling streamlined collaboration and task
                allocation during daily stand-ups, ensuring rapid progress and
                alignment with project goals.
              </p>
            </div>
            <div className="flex flex-col mt-4 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                This project not only sharpened my technical acumen in mobile
                development with React Native and platform-specific tools like
                Xcode and Android Studio but also deepened my grasp of agile
                project management and cohesive teamwork through Jira, all while
                building a user-centric mobile solution.
              </p>
            </div>
            <div className="flex flex-col mt-4 w-10/12 sm:w-10/12 md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
              <p className="mt-8 font-gt text-[#D3EDE4] text-xl leading-9">
                *Images are the property of IRLY. I do not claim ownership of
                these images and did not produce or design them. They are used
                here solely to showcase my work experience at IRLY.
              </p>
            </div>
            <div
              onClick={() => (window.location.href = "/getmearoomie")}
              className="group flex flex-col mt-24 w-10/12 sm:w-10/12 md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 justify-center items-center border-3 border-transparent hover:border-[#2b5f4e] duration-1000 transition-all ease-in-out py-10 sm:py-10 md:py-24 lg:py-24 xl:py-24 2xl:py-24 rounded-full cursor-pointer"
            >
              <p className="font-acorn text-center text-[#8FDBC2] text-4xl sm:text-4xl md:text-8xl lg:text-8xl xl:text-8xl 2xl:text-8xl group-transition-transform duration-300 ease-in-out transform group-hover:-translate-y-2.5">
                Get Me a Roomie.
              </p>
            </div>
          </div>
        </Content>
        <Footer />
        <Appearance />
      </Layout>
    </App>
  );
}
